<template>
  <div class="main">
   <Header/>
    <div class="province">
      <div
        class="province-item"
        @click="$router.push(`/reviewNews?pid=${i.id}`)"
        v-for="(i, index) in provinceList"
      >
        <span class="hebei">{{ i.name }}</span>
        <img
          style="width: 100%; height: 100%"
          :src="colorlists[index % 4]"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  data() {
    return {
      provinceList: [],
      colorlists: [
        require("../assets/images/img_one.png"),
        require("../assets/images/img_two.png"),
        require("../assets/images/img_three.png"),
        require("../assets/images/img_four.png"),
      ],
    };
  },
  created() {
    this.getProvinceList();
  },
  methods: {
    getProvinceList() {
      this.axios.get("/home/province_list").then((res) => {
        console.log(res);
        this.provinceList = res.data;
      });
    },
  },
  components:{ 
     Header,
  }
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 100vh;
  .province {
    width: 80%;
    height: 80%;
    padding-top: 20px;
    // background:#f8f8f8;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .province-item {
      width: 188px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      background: #fff;
      margin-left: 14.5px;
      margin-right: 14.5px;
      margin-top: 33px;
      position: relative;
      .hebei {
        font-size: 23px;
        color: #fff;
        font-weight: bold;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .province-item:hover{
      cursor:pointer;
    }
  }
}
</style>
